<template>
  <div class="box">
    <Breadcrumb class="navigation">
      <BreadcrumbItem v-for="item in breadMenuData"
        :to="item.path">
        {{ item.name }}
      </BreadcrumbItem>
    </Breadcrumb>
    <div class="introduce-box">
      <p class="sp">{{ $t('key1005353') }}</p>
      <p class="tit">{{ $t('key1005354') }}</p>
      <p class="sp1 center">{{ $t('key1005355') }}</p>
      <div class="img-center">
        <img :src="imgUrl" />
      </div>
      <div class="img-bottom-text">
        <div class="item">
          <p class="s-tit">1. {{ $t('key1005356') }}</p>
          <p class="img-description">{{ $t('key1005357') }}</p>
        </div>
        <div class="item">
          <p class="s-tit">2. {{ $t('key1005358') }}</p>
          <p class="img-description">{{ $t('key1005359') }}</p>
        </div>
        <div class="item">
          <p class="s-tit">3. {{ $t('key1001098') }}</p>
          <p class="img-description">{{ $t('key1005360') }}</p>
        </div>
      </div>
      <p class="tit">{{ $t('key1005361') }}</p>
      <p class="sp1">{{ $t('key1005362') }}</p>
      <div class="tags">
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005363') }}</p>
          <p class="tag-description">{{ $t('key1005364') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005365') }}</p>
          <p class="tag-description">{{ $t('key1005366') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005367') }}</p>
          <p class="tag-description">{{ $t('key1005368') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1002681') }}</p>
          <p class="tag-description">{{ $t('key1005369') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005370') }}</p>
          <p class="tag-description">{{ $t('key1005359') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005371') }}</p>
          <p class="tag-description">{{ $t('key1005372') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005373') }}</p>
          <p class="tag-description">{{ $t('key1005374') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005375') }}</p>
          <p class="tag-description">{{ $t('key1005364') }}</p>
        </div>
        <div class="tag">
          <p class="tag-tit">{{ $t('key1005376') }}</p>
          <p class="tag-description">{{ $t('key1005377') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';


export default {
  name: 'introduce',
  data () {
    return {
      imgUrl: require('@/assets/images/introduce_01.png'),
      breadMenuData: [{
        path: '/index',
        name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005264')
      },{
        path: '/introduce',
        name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000042')
      }]
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 0 auto;
  .navigation {
    padding: 10px;
  }
  .introduce-box {
    background-color: #ffffff;
    padding: 25px;
    margin-bottom: 20px;
    .tit {
      text-align: center;
      font-size: 24px;
      color: #000000;
      margin: 30px 0 10px 0;
    }
    .sp1 {
      text-align: center;
      margin-bottom: 20px;
    }
    .img-center {
      width: 620px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .img-bottom-text {
      width: 808px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .item{
        width: 212px;
        .s-tit {
          color: #333333;
          font-size: 14px;
          text-align: center;
          margin: 8px;
        }
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      width: 363px;
      height: 170px;
      border: 1px solid #d7d7d7;
      text-align: center;
      margin: 10px;
      .tag-tit {
        padding-top: 53px;
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        color: #FF5310;
        margin-bottom: 10px;
        font-size: 16px;
      }
      .tag-description {
        font-family: 'Arial Normal', 'Arial', sans-serif;
        font-weight: 400;
        color: #000000;
      }
    }
  }
}


.img-center {
  text-align: center;
}
</style>
